@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Josefin+Slab:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Dancing+Script:wght@400;500;600;700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Alkatra:wght@400;500;600;700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Grandiflora+One&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Tangerine:wght@400;700&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Zen+Maru+Gothic:wght@300;400;500;700;900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

:root {
    --bege-escuro: #D09E8C;
    --bege-darker: #ad8374;
    --bege-claro: #FEF9F3;
}

#main {
    overflow: hidden !important;
    position: relative;
}

#main::before {
    content: "";
    position: absolute;
    top: -5%;
    left: -5%;
    width: 110%;
    height: 110%;
    background-image: url('img/bg-terapia-1.jpg');
    background-size: cover;
    background-position: center;
    /* filter: blur(1px); */
    z-index: -1;
}

body {
    font-family: 'Montserrat', sans-serif;
    /* font-family: 'Roboto', sans-serif; */
    /* background-color: #EDCEC3; */
    background: linear-gradient(45deg, #ffefee, #FEF9F3 25%, #f3dddd 85%);
}

.navbar {
    font-family: 'Montserrat', sans-serif;
    /* background-color: #FEF9F3 !important; */
    background: rgba(255, 255, 255, 0.2) !important;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1) !important;
    backdrop-filter: blur(5px) !important;
    -webkit-backdrop-filter: blur(5px) !important;
}

.navbar-item-custom {
    font-size: 1.2rem !important;
}

.disclaimer {
    font-family: 'Lato', sans-serif;
}
#navbar-titulo {
    /* font-family: 'Tangerine', cursive; 
    font-size: 2.8rem !important;*/
    /* font-family: 'Grandiflora One', cursive;
    font-size: 1.8rem !important; */
    font-family: 'Dancing Script', cursive;
    font-size: 2rem !important;
    font-weight: 700 !important;
}

.sombra-texto {
    text-shadow: 0px 0px 6px rgba(0, 0, 0, 9);
}

#img-logo {
    width: 60px;
}

/* .texto-carousel {
    position: absolute;
    left: 0;
    bottom: -50px;
    color: white;
    padding: 0 15px;
    background-color: rgba(255, 255, 255, 0.8);
    transition: bottom 0.5s, opacity 0.5s;
    pointer-events: none;
    opacity: 0;
}

.div-carousel:hover .texto-carousel {
    bottom: 0;
    opacity: 1;
    transition: 0.5s
} */

.texto-carousel {
    position: absolute;
    left: 0;
    bottom: 0;
    color: white;
    width: 100% !important;
    background-color: rgba(255, 255, 255, 0.8);
    transition: bottom 0.5s, opacity 0.5s;
    pointer-events: none;
    opacity: 1;
}

@media (min-width: 576px){
    .texto-carousel {
        bottom: -50px;
        color: white;
        pointer-events: none;
        opacity: 0;
    }
    
    .div-carousel:hover .texto-carousel {
        bottom: 0;
        opacity: 1;
        transition: 0.5s
    }

}

.div-carousel {
    cursor: pointer;
}

.bg-blur {
    background: rgba(255, 255, 255, 0.2) !important;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1) !important;
    backdrop-filter: blur(5px) !important;
    -webkit-backdrop-filter: blur(5px) !important;
}

.load-spinner {
    -webkit-animation: spin .5s linear infinite;
    -moz-animation: spin .5s linear infinite;
    animation: spin .5s linear infinite;
}

@-moz-keyframes spin {
    100% {
        -moz-transform: rotate(360deg);
    }
}

@-webkit-keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@keyframes spin {
    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.texto-inicio {
    font-family: 'Montserrat', sans-serif;
    /* text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2); */
    /* font-family: 'Alkatra', cursive; */
    /* font-family: 'Roboto', sans-serif; */
    /* font-family: 'Zen Maru Gothic', cursive; */
    /* font-family: 'Josefin Slab', serif; */
}

.subtexto-inicio {
    font-family: 'Montserrat', sans-serif;
    /* text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2); */
    /* font-family: 'Alkatra', cursive; */
    /* text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.9); */
    /* font-family: 'Dancing Script', cursive; */

}

.background-texto-inicio {
    background-color: rgba(254, 249, 243, .8);
}

.texto-inicio-carousel {
    height: 270px;
    background-color: rgba(254, 249, 243, .1);
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 2);
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (min-width: 576px)   {
    .texto-inicio-carousel {
        height: 180px;
        padding: 0 150px ;
        font-size:1.25rem;
    }
}

@media (min-width: 576px)   {
    .texto-inicio-carousel {
        padding: 0 100px ;
    }
}

@media (min-width: 1400px) {
    .texto-inicio-carousel {
        padding: 0 150px ;
        font-size: 1.7rem;
    }
}

.btn-profissionais {
    position: relative;
    border: none;
    font-size: 20px;
    cursor: pointer;
}


.btn-profissionais::before {
    content: "";
    position: absolute;
    background: var(--bs-danger-text-emphasis) !important;
    width: 100%;
    height: 3px;
    left: 0;
    bottom: 0;
    transform: scaleX(0);
    transition: .3s ease-out;
}

.btn-profissionais:hover::before {
    transform: scaleX(1);
}

.btn-agendar-consulta {
    background-color: rgba(254, 249, 243, .8);
    font-size: 1.25rem;
    margin: 10px 0px; 
}

@media (min-width: 1400px){
    .btn-agendar-consulta {
        font-size: 1.5rem;       
        margin: 20px 0px; 
    }
}

.background-profissional {
    background-color: #FEF9F3;
}

.bg-dados-terapia {
    background: linear-gradient(to bottom, transparent, #c9b9b6) !important;
}

.min-vh-40 {
    min-height: 60vh;
}

.min-vh-60 {
    min-height: 60vh;
}

.min-vh-70 {
    min-height: 70vh;
}

.limpar-select {
    cursor: pointer;
}

.min-h-miniatura {
    min-height: 22rem !important;
}

.border-bege {
    border-color: var(--bege-escuro) !important;
}

.text-bege {
    color: var(--bege-escuro) !important;
}

.text-bege-claro {
    color: var(--bege-claro) !important;
}

.bg-sobre {

    overflow: hidden !important;
    position: relative;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.bg-sobre::before {
    content: "";
    background-image: url('img/img-terapia-2.jpg');
    filter: blur(5px) brightness(40%);
    position: absolute;
    top: -5%;
    left: -5%;
    width: 110%;
    height: 110%;
    background-size: cover;
    background-position: center;
    z-index: -1;
}


.bg-blur-sobre {
    background: rgba(255, 255, 255, 0.2) !important;
    box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1) !important;
    backdrop-filter: blur(5px) !important;
    -webkit-backdrop-filter: blur(5px) !important;
    border-radius: 15px;
}

/* 
.bg-sobre-texto{
    background-color: rgba(0, 0, 0, 0.3);
} */

#profissional {
    background-color: var(--bege-escuro);
}

.btn-agendar {
    color: var(--bege-claro) !important;
    background-color: var(--bege-escuro) !important;
}

.btn-agendar:hover {
    border-color: var(--bege-escuro) !important;
    color: var(--bege-escuro) !important;
    background-color: transparent !important;
}

.btn-bege {
    color: var(--bege-claro) !important;
    background-color: var(--bege-escuro) !important;
}

.btn-bege:hover {
    border-color: var(--bege-escuro) !important;
    color: var(--bege-escuro) !important;
    background-color: transparent !important;
}


.btn-outline-danger-emphasis {
    background-color: #58151C !important;
}

.btn-outline-danger-emphasis:hover {
    color: #58151C !important;
    border-color: #58151C !important;
    background-color: transparent !important;
}

.bg-encontre-psi {
    overflow: hidden !important;
    position: relative;
    /* text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5); */
}

.bg-encontre-psi::before {
    content: "";
    background-image: url('img/img2.jpg');
    /* filter: blur(1px); */
    filter: grayscale(40%);
    position: absolute;
    top: -5%;
    left: -5%;
    width: 110%;
    height: 110%;
    background-size: cover;
    background-position: center;
    z-index: -1;
}

.bg-branco-transparente{
    background-color: rgba(255, 255, 255, 0.7);
}
.btn-voltar:hover{
    --bs-link-color-rgb: black;
    text-decoration: none !important;

}

.btn-agendar-sessao {
    background-color: var(--bege-claro);
}

#img-atendimento-online-1 {
    /* filter:hue-rotate(80deg) */
}

ul {
    padding: 0;
    margin: 0;
    list-style: none;
}

.footer {
    /* background: linear-gradient(45deg, #ffefee, #FEF9F3 25%, #f3dddd 85%); */
    background: linear-gradient(to bottom, transparent, #c9b9b6) !important;
    font-size: 14px;
}

.footer-dev {
    font-size: 14px;
}


.background-avaliacoes {
    background-color: rgba(151, 151, 151, 0.1);
    cursor: default;
}

.speech-bubble {
    filter: drop-shadow(-1px -1px 2px rgba(0, 0, 0, 0.1)) drop-shadow(1px 2px 2px rgba(0, 0, 0, 0.15));
    margin: 1rem;
    margin-bottom: 40px;
    padding: 1rem 2rem;
    position: relative;
    /* font-family: "Source Sans Pro", sans-serif; */
    /* font-size: 1.2rem; */
    font-weight: 400;
    background: #f0cfc3;
}

.speech-bubble::before {
    border: 12.5px solid transparent;
    border-top: 12.5px solid #f0cfc3;
    border-bottom: 0;
    height: 0;
    width: 0;
    border-top-width: 25px;
    content: "";
    display: block;
    position: absolute;
    left: 3rem;
    bottom: -25px;
    transform-origin: center;
    transform: rotate(90deg) skew(-25deg) translateY(16.6666666667px);
}